// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-appear {
    cursor: default;
    display: inline;
    position: relative;
}

.section-title-char {
    color: transparent;
}

.text-appear .section-title-char {
    color: unset;
}

.section-title-char-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    transition: all 1s both;
}

.text-appear .section-title-char-cover {
    animation: hide 1s forwards;
}

@keyframes hide {
    to {
        height: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/section-sub-title.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,eAAe;IAIf,kBAAkB;AAHtB;;AAMA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,UAAU;IACV,uBAAuB;AAC3B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".text-appear {\n    cursor: default;\n    display: inline;\n}\n\n.text-appear {\n    position: relative;\n}\n\n.section-title-char {\n    color: transparent;\n}\n\n.text-appear .section-title-char {\n    color: unset;\n}\n\n.section-title-char-cover {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    pointer-events: none;\n    z-index: 1;\n    transition: all 1s both;\n}\n\n.text-appear .section-title-char-cover {\n    animation: hide 1s forwards;\n}\n\n@keyframes hide {\n    to {\n        height: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
