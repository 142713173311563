// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-parent {
    position: relative;
    overflow-y: hidden;
}

:root {
    --custom-height-image-child: 1000px;
    /* Default Image Child Height */
}

.image-child {
    position: absolute;
    bottom: 0;
    display: none;
    width: 100%;
    height: var(--custom-height-image-child);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
    padding: 0;
    transition: all 2s ease-in-out;
}


.image-child-of-child {
    flex-grow: 1;
    background-color: rgb(255, 255, 255);
}


.animate-image .image-parent>.image-child {
    bottom: calc(0px - var(--custom-height-image-child));
}`, "",{"version":3,"sources":["webpack://./src/styles/imageWaveAnimation.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,mCAAmC;IACnC,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,WAAW;IACX,wCAAwC;IACxC,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,MAAM;IACN,UAAU;IACV,8BAA8B;AAClC;;;AAGA;IACI,YAAY;IACZ,oCAAoC;AACxC;;;AAGA;IACI,oDAAoD;AACxD","sourcesContent":[".image-parent {\n    position: relative;\n    overflow-y: hidden;\n}\n\n:root {\n    --custom-height-image-child: 1000px;\n    /* Default Image Child Height */\n}\n\n.image-child {\n    position: absolute;\n    bottom: 0;\n    display: none;\n    width: 100%;\n    height: var(--custom-height-image-child);\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    gap: 0;\n    padding: 0;\n    transition: all 2s ease-in-out;\n}\n\n\n.image-child-of-child {\n    flex-grow: 1;\n    background-color: rgb(255, 255, 255);\n}\n\n\n.animate-image .image-parent>.image-child {\n    bottom: calc(0px - var(--custom-height-image-child));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
