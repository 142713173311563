import React, { useEffect, useId, useRef, useState } from "react";
import SoftwareSolutionCard from "./SoftwareSolutionCard";
import web_and_dev from "../assets/svgs/software-solutions/web-and_dev.svg";
import customer_dev_solution from "../assets/svgs/software-solutions/customer-dev-solution.svg";
import colud_solution from "../assets/svgs/software-solutions/cloud-solution.svg";
import ai_and_machine_leaning_solution from "../assets/svgs/software-solutions/ai-and-machine-learning.svg";
import cyber_security_solution from "../assets/svgs/software-solutions/cyber-security.svg";

const cardList = [
  {
    title: (
      <>
        Customer
        <br /> Software
        <br /> Development
      </>
    ),
    description: (
      <>
        Our team of experienced developers excels in creating bespoke software
        solutions tailored to meet the unique needs and challenges of
      </>
    ),
    parentClass: "",
    positionClass: "ml-[35px] mb-[1.3em] transform -rotate-6 mt-[77px]",
    image: customer_dev_solution,
    tooltip: (
      <>
        Our team of experienced developers excels in creating bespoke software
        solutions tailored to meet the unique needs and challenges of our
        clients. From conceptualization to deployment, we ensure a seamless and
        efficient development process.
      </>
    ),
    bgColor: "#fff",
    titleColor: "#111748",
    descriptionColorClass: "text-[#111748]",
  },
  {
    title: (
      <>
        Web and
        <br />
        Mobile App
      </>
    ),
    description: (
      <>
        Harnessing the latest technologies, we specialize in crafting responsive
        and user-friendly web and mobile applications. Whether it's
      </>
    ),
    parentClass: "",
    positionClass: "rotate-1.5 z-20",
    image: web_and_dev,
    bgColor: "#111748",
    tooltip: (
      <>
        Harnessing the latest technologies, we specialize in crafting responsive
        and user-friendly web and mobile applications. Whether it's iOS,
        Android, or cross-platform development, we ensure a consistent and
        engaging user experience.
      </>
    ),
  },
  {
    title: (
      <>
        Cloud
        <br />
        Solutions
      </>
    ),
    description: (
      <>
        Embrace the scalability and flexibility of the cloud with our expertly
        designed cloud solutions. From migration to optimization, we empower
      </>
    ),
    parentClass: "",
    positionClass: "rotate-3 ml-5 mt-5 z-10",
    image: colud_solution,
    tooltip: (
      <>
        Embrace the scalability and flexibility of the cloud with our expertly
        designed cloud solutions. From migration to optimization, we empower
        businesses to harness the full potential of cloud computing.
      </>
    ),
    bgColor: "#FFC641",
    titleColor: "#111748",
    descriptionColorClass: "text-[#111748]",
    titleHeightClass: "h-[80px] pb-10",
  },
  {
    title: (
      <>
        AI and
        <br /> Machine
        <br /> Learning
      </>
    ),
    description: (
      <>
        Stay ahead of the curve with our AI and machine learning services. We
        develop intelligent solutions that automate processes, analyze data
      </>
    ),
    parentClass: "",
    positionClass: "rotate-[8deg] mt-[26px]",
    image: ai_and_machine_leaning_solution,
    tooltip: (
      <>
        Stay ahead of the curve with our AI and machine learning services. We
        develop intelligent solutions that automate processes, analyze data, and
        provide valuable insights to drive informed decision-making.
      </>
    ),
    bgColor: "#FFF",
    titleColor: "#111748",
    descriptionColorClass: "text-[#111748]",
    titleHeightClass: "",
  },
  {
    title: (
      <>
        Cyber
        <br />
        Security
      </>
    ),
    description: (
      <>
        Safeguard your digital assets with our robust cybersecurity services. We
        implement cutting-edge measures to protect against cyber... Read More
      </>
    ),
    parentClass: "",
    positionClass: "mr-[35px] rotate-[1.54deg] mt-[10px]",
    image: cyber_security_solution,
    tooltip: (
      <>
        Safeguard your digital assets with our robust cybersecurity services. We
        implement cutting-edge measures to protect against cyber threats,
        ensuring the confidentiality, integrity, and availability of your data.
      </>
    ),
    bgColor: "#111748",
    titleColor: "#FFFFFFE6",
    descriptionColorClass: "text-[#FFFFFFE6]",
    titleHeightClass: "h-[80px] pb-10",
  },
];

const SoftwareSolutionsCardsSection = () => {
  const cardsSectionId = useId();
  const containerRef = useRef(null);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(true); // true for right, false for left
  const [isScrolling, setIsScrolling] = useState(true);

  const handleGrabStart = () => {
    setIsGrabbing(true);
    setIsScrolling(false);
  };

  const handleGrabEnd = () => {
    setIsGrabbing(false);
    setIsScrolling(true);
  };

  const handleMouseMove = (event) => {
    if (!isGrabbing) return;
    const x = event.clientX - containerRef.current.offsetLeft;
    const startX = event.clientX - containerRef.current.offsetLeft;
    const scrollLeft = containerRef.current.scrollLeft;
    const scrollX = x - startX;
    containerRef.current.scrollLeft = scrollLeft - scrollX;
  };

  const horizontalScroll = () => {
    const maxScrollLeft =
      containerRef.current.scrollWidth - containerRef.current.clientWidth;

    if (scrollDirection) {
      containerRef.current.scrollLeft += 1;
      if (containerRef.current.scrollLeft >= maxScrollLeft) {
        setScrollDirection(false);
      }
    } else {
      containerRef.current.scrollLeft -= 1;
      if (containerRef.current.scrollLeft <= 0) {
        setScrollDirection(true);
      }
    }
  };

  useEffect(() => {
    let intervalId = null;

    if (isScrolling) {
      intervalId = setInterval(horizontalScroll, 10);
    }

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrolling, scrollDirection]);

  useEffect(() => {
    const sectionCardsElement = document.getElementById(cardsSectionId);
    sectionCardsElement.addEventListener("mouseleave", startScrolling);
    sectionCardsElement.addEventListener("mouseenter", stopScrolling);

    return () => {
      sectionCardsElement.removeEventListener("mouseleave", startScrolling);
      sectionCardsElement.removeEventListener("mouseenter", stopScrolling);
    };
  }, [cardsSectionId]);

  const startScrolling = () => {
    setIsScrolling(true);
  };

  const stopScrolling = () => {
    setIsScrolling(false);
  };

  return (
    <div className="relative flex-grow overflow-x-auto hide-scroll-bar overflow-y-hidden">
      <div className="absolute top-0 left-0 h-full w-2 tablet:w-6 laptop:w-10 bg-gradient-to-r from-[#ECECF0] to-[#ECECF000] from-70% z-50"></div>
      <div className="absolute top-0 right-[-4px] laptop:right-0 h-full w-2 tablet:w-6 laptop:w-10 bg-gradient-to-l from-[#ECECF0] from-70% z-50"></div>
      <div
        id={cardsSectionId}
        className={`${
          isGrabbing ? "cursor-grabbing" : "cursor-grab"
        } overflow-x-auto hide-scroll-bar overflow-y-hidden`}
        ref={containerRef}
        onMouseDown={handleGrabStart}
        onMouseUp={handleGrabEnd}
        onMouseMove={handleMouseMove}
        onTouchStart={handleGrabStart}
        onTouchEnd={handleGrabEnd}
      >
        <div className="flex gap-0 relative py-13">
          <div className="h-full w-20 text-transparent">ssss</div>
          {cardList.map((card, i) => (
            <SoftwareSolutionCard
              key={i}
              title={card.title}
              description={card.description}
              parentClass={`${card.parentClass} ${card.positionClass}`}
              image={card.image}
              tooltip={card.tooltip}
              bgColor={card.bgColor}
              descriptionColorClass={card.descriptionColorClass}
              titleColor={card.titleColor}
              titleHeightClass={card.titleHeightClass}
              onTooltipOpen={stopScrolling}
              onTooltipClose={startScrolling}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SoftwareSolutionsCardsSection;
