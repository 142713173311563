import React, { useEffect, useState } from "react";
import "../styles/header.css";
import pbLogo from "../assets/svgs/PBLogo.svg";
import PBLogoDark from "../assets/svgs/PBLogoDark.svg";
import pbLogoSmall from "../assets/svgs/PBLogo-small.svg";
import PBLogoDarkSmall from "../assets/svgs/PBLogoDark-small.svg";
import { Link } from "react-scroll";
import burgerBtnIcon from "../assets/icons/burgerBtnIcon.svg";
import burgerBtnIconClose from "../assets/icons/burgerBtnIconClose.svg";
import burgerBtnIconDark from "../assets/icons/burgerBtnIconDark.svg";
import ShakingBtn from "./animation/ShakingBtn";

const Header = () => {
  const headerList = [
    { name: "Home", id: "home" },
    { name: "Mobile Operators", id: "mobileOperators" },
    { name: "Enterprise Solutions", id: "enterpriseSolutions" },
    { name: "Software Services", id: "softwareSolutions" },
    { name: "Payment Solutions", id: "paymentSolutions" },
    { name: "Cyber Security", id: "cyberSecurity" },
    { name: "Resource Augmentation", id: "resourceAugmentation" },
  ];

  const [scrolled, setScrolled] = useState(false);
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(headerList[0].id);

  const toggleNavMenu = () => {
    setIsNavMenuOpen(!isNavMenuOpen);
  };

  const NavBtn = () => {
    const icon = isNavMenuOpen
      ? burgerBtnIconClose
      : scrolled
      ? burgerBtnIconDark
      : burgerBtnIcon;
    return (
      <button onClick={toggleNavMenu}>
        <img src={icon} alt="Button" />
      </button>
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        id="header"
        className={`fixed z-[10000] px-16 py-6 hidden laptop:flex justify-around laptop:space-x-2 desktop:space-x-14 items-center w-full text-[16px] desktop:text-[18px] ${
          scrolled
            ? "bg-[#fff] text-[#11174899]"
            : "text-[#ffffffB3] bg-transparent font-[300]"
        } `}
      >
        <img
          className="hidden desktop:block"
          src={scrolled ? PBLogoDark : pbLogo}
          alt={"logo"}
        />
        <img
          className="desktop:hidden"
          src={scrolled ? PBLogoDarkSmall : pbLogoSmall}
          alt={"logo"}
        />
        <div className="flex space-x-6 desktop:space-x-10 items-baseline ">
          {headerList.map((headerItem, i) => (
            <div key={i} className="nav-link">
              <Link
                key={i}
                activeClass={`selected-nav-link rounded-5 font-[500] mt-1 ${
                  scrolled
                    ? "text-[#18A6AB] hover:text-[#18A6AB]"
                    : "text-[#ffffff] hover:text-[#ffffff]"
                }`}
                to={headerItem.id}
                spy={true}
                smooth={false}
                offset={-100}
                duration={500}
                className={`cursor-pointer transform transition-all duration-500 ${
                  scrolled ? "hover:text-[#111748]" : "hover:text-white"
                } ${activeLink === headerItem.id ? "selected-nav-link" : ""}`}
                onSetActive={() => setActiveLink(headerItem.id)}
              >
                {headerItem.name}
                <div className="navlink-bottom-line bg-[#18A6AB] mt-[3px]"></div>
              </Link>
            </div>
          ))}
        </div>
        <ShakingBtn
          className={`transform transition-all cursor-pointer duration-500   bg-[#ffc641] hover:bg-[#ffd46f] px-6 py-2 hover:px-7 rounded-[52px] text-[#111748CC] font-[600] whitespace-nowrap`}
        >
          <Link
            activeClass="text-[#124563]"
            to={"letsChat"}
            spy={true}
            smooth={false}
            offset={-70}
            duration={500}
          >
            Let's Chat?
          </Link>
        </ShakingBtn>
      </nav>
      {/* Nav Bar For Mobie View */}
      <nav
        id="header"
        className={`fixed z-[10000] laptop:hidden w-full ${
          isNavMenuOpen
            ? "bg-[#fff] h-full"
            : scrolled
            ? "bg-[#fff]"
            : "bg-transparent"
        }`}
      >
        <div
          className={`flex justify-between items-center px-7 py-6 w-full text-[18px] ${
            scrolled
              ? "border-b-[1px]- border-b-[#11174833]-"
              : isNavMenuOpen
              ? "border-b-[1px]- border-b-[#11174833]-"
              : ""
          } `}
        >
          <img
            src={
              scrolled
                ? PBLogoDarkSmall
                : isNavMenuOpen
                ? PBLogoDarkSmall
                : pbLogoSmall
            }
            alt={"logo"}
          />
          <NavBtn />
        </div>
        {isNavMenuOpen && (
          <div className="bg-white px-16 py-10 space-y-6">
            {headerList.map((headerItem, i) => (
              <div key={i}>
                <Link
                  onClick={toggleNavMenu}
                  activeClass="rounded-5 font-[500] mt-1 text-[#111748]"
                  to={headerItem.id}
                  spy={true}
                  smooth={false}
                  offset={-90}
                  duration={500}
                  className={`text-[21px] font-[300] leading-none text-[#111748B3] cursor-pointer transform transition-all duration-500 hover:font-[400] hover:text-shadow-glow`}
                >
                  {headerItem.name}
                </Link>
              </div>
            ))}
            <div className="pt-11 max-w-[139px]">
              <div
                className={`transform transition-all cursor-pointer duration-500 bg-[#ffc641] hover:bg-[#ffd46f] px-6 py-2 rounded-[52px] text-[#111748CC] font-[600] whitespace-nowrap flex justify-center`}
              >
                <Link
                  onClick={toggleNavMenu}
                  activeClass="text-[#124563]"
                  to={"letsChat"}
                  spy={true}
                  smooth={false}
                  offset={-70}
                  duration={500}
                  className="text-center"
                >
                  Let's Chat?
                </Link>
              </div>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Header;
