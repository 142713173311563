// Import React library along with useEffect and useState hooks
import React from "react";

// Import styles specific to the PaymentSolutions component
import "../styles/paymentSolutions.css";

// Import the paymentSolution image
import paymentSolution from "../assets/svgs/paymentsolutions.jpg";
import SectionTitle from "../reusable/SectionTitle";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import ImageWithAnimation from "./animation/ImageWithAnimation";
import SectionSubTitle from "../reusable/SectionSubTitle";

// Define the PaymentSolutions functional component
const PaymentSolutions = () => {
  /*useEffect(() => {
    const handleScroll = () => {
      const elementIsInViewPortConst = elementIsInViewPort(
        "payment-solutions-title", 50, 30
      );
      console.log(elementIsInViewPortConst);
    };
    // Attach event listener for scrolling
    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  */

  // Return the JSX structure for the PaymentSolutions component
  return (
    <section
      id="paymentSolutions"
      className="tablet:py-[110px] py-[70px] min-h-[100vh] px-5 tablet:px-[67px]"
    >
      {/* <div className="flex justify-center">
        <div
          id="payment-solutions-title"
          className={`text-center font-40-to-108`}
        >
          <p className="leading-none text-left font-[300] text-[#111748]">
            PAYMENT
          </p>
          <p className="text-right font-[900] leading-none text-[#111748]">
            SOLUTIONS
          </p>
        </div>
      </div> */}
      <SectionTitle
        className={"text-center font-40-to-108 bg-red-500-"}
        firstChild={
          <p className="leading-none text-left- font-[300] text-[#111748] bg-blue-400-">
            PAYMENT
          </p>
        }
        secondChild={
          <p className="text-right- font-[900] leading-none text-[#111748] bg-green-500-">
            SOLUTIONS
          </p>
        }
        viewPortStartPointFromBottom={0}
        viewPortEndPointFromBottom={50}
        firstChildTranslateAtStart={window.innerWidth * 0.3}
      />
      <div className="tablet:flex tablet:flex-row flex flex-col items-center tablet:space-x-20 laptop:mt-[120px] tablet:mt-[80px] mt-[39px]">
        {/* Image for the Payment Solutions section */}
        <div className="w-[90vw] tablet:w-[50%]">
          {/* <img className="w-full h-full" src={paymentSolution} alt="" /> */}
          <ImageWithAnimation src={paymentSolution} />
        </div>
        {/* Text content for the Payment Solutions section */}
        <div className="space-y-[36px] tablet:w-[50%] w-auto mt-[32px] laptop:mt-[0px]">
          {/* Heading for the Payment Solutions section */}
          {/* <p className="text-[#111748] font-[900] uppercase laptop:text-[48px] tablet:text-[38px] text-[27px]">
            Payment APIs for airtime top-ups made simple!
          </p> */}
          <SectionSubTitle
            className={
              "text-[#111748] font-[900] uppercase laptop:text-[48px] tablet:text-[38px] text-[27px]"
            }
            text={"Payment APIs for airtime top-ups made simple!"}
          />
          <div>
            {/* Subheading and description for the Payment Solutions section */}
            <p className="text-[#111748] font-[500] laptop:text-[27px] tablet:text-[23px] text-[20px]">
              Enable your apps and websites to send mobile top-up worldwide.
            </p>
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {" "}
              <p className="text-[#111748] font-[300] laptop:text-[22px] tablet:text-[19px] text-[16px]">
                Whether you’re a startup or a big corporation, you can use our
                payment gateway for sending mobile top-ups globally. We offer a
                transparent billing system based on usage, with no long-term
                contracts or hidden fees.
              </p>
            </AnimateOnDisplay>
          </div>
        </div>
      </div>
    </section>
  );
};

// Export the PaymentSolutions component as the default export
export default PaymentSolutions;
