export default function elementIsInViewPort(
  elementId,
  completeTop,
  startBottom
) {
  const topPoint = completeTop || 100;
  const bottomPoint = startBottom || 0;
  const element = document.getElementById(elementId);
  if (element) {
    const elementTop = element.getBoundingClientRect().top;
    if (elementTop < 0) {
      return 100;
    }
    const viewportHeight = window.innerHeight;
    const elementTopInViewPort =
      (100 * (viewportHeight - elementTop)) / viewportHeight;
    /*console.log(
      `elementTop: ${elementTop}, viewportHeight: ${viewportHeight}, elementTopInViewPort: ${elementTopInViewPort}, topPoint: ${topPoint}, bottomPoint: ${bottomPoint}`
    );*/
    //TO MOVE UP

    if (topPoint === 100 && bottomPoint === 0) {
      return elementTopInViewPort;
    }

    // Formula : Percentage = ((Value - LowerBound) / (UpperBound - LowerBound)) * 100
    let valueToReturn =
      ((elementTopInViewPort - bottomPoint) / (topPoint - bottomPoint)) * 100;

    if (valueToReturn < 0) {
      return 0;
    } else if (valueToReturn > 100) {
      return 100;
    }
    return valueToReturn;
  }
  return 0;
}
