import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import "./styles/animation.css";
import "./styles/fontSizing.css";
import { BaseRoute } from "./constants/RouteConstants";
import LandingPage from "./pages/LandingPage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import useScrollToTop from "./hooks/useScrollToTop";

function App() {
  useScrollToTop();

  return (
    <>
      <Header />
      <Routes>
        <Route path={BaseRoute} element={<LandingPage />} />
        <Route path="*" element={<Navigate to={BaseRoute} replace />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
