// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.beat-anim {
  animation: beat 1s infinite alternate;
  transform-origin: center;
}

.shake-anim {
  animation: shake 3s infinite forwards;
  /* transform-origin: center; */
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.snap-in {
  opacity: 0;
  transition: all;
  animation: snap 2s normal forwards;
}

@keyframes snap {
  0% {
    transform: translate3d(0px, 20px, -1000px);
    opacity: 0;
  }

  100% {
    transform: translate3d(0px, 0px, 0);
    opacity: 100;
  }
}

@keyframes snap-new {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,wBAAwB;AAC1B;;AAEA;EACE,qCAAqC;EACrC,8BAA8B;AAChC;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,UAAU;EACV,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE;IACE,0CAA0C;IAC1C,UAAU;EACZ;;EAEA;IACE,mCAAmC;IACnC,YAAY;EACd;AACF;;AAEA;EACE;IACE,UAAU;IACV,4BAA4B;EAC9B;EACA;IACE,UAAU;IACV,wBAAwB;EAC1B;AACF;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;AACd;;AAEA,gDAAgD;AAChD;EACE,aAAa;AACf","sourcesContent":[".beat-anim {\n  animation: beat 1s infinite alternate;\n  transform-origin: center;\n}\n\n.shake-anim {\n  animation: shake 3s infinite forwards;\n  /* transform-origin: center; */\n}\n\n@keyframes beat {\n  0% {\n    transform: scale(1);\n  }\n\n  100% {\n    transform: scale(1.1);\n  }\n}\n\n.snap-in {\n  opacity: 0;\n  transition: all;\n  animation: snap 2s normal forwards;\n}\n\n@keyframes snap {\n  0% {\n    transform: translate3d(0px, 20px, -1000px);\n    opacity: 0;\n  }\n\n  100% {\n    transform: translate3d(0px, 0px, 0);\n    opacity: 100;\n  }\n}\n\n@keyframes snap-new {\n  from {\n    opacity: 0;\n    transform: translateX(-100%);\n  }\n  to {\n    opacity: 1;\n    transform: translateX(0);\n  }\n}\n\n.hide-scroll-bar {\n  -ms-overflow-style: none;\n  /* IE and Edge */\n  scrollbar-width: none;\n  /* Firefox */\n}\n\n/* Hide scrollbar for Chrome, Safari and Opera */\n.hide-scroll-bar::-webkit-scrollbar {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
