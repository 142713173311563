// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/svgs/cyberSecurity.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../assets/icons/bulletsBlue.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../assets/svgs/cyberSecurity-sm.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cyberParent {
  background: linear-gradient(90deg, #ffc641 0%, rgba(255, 255, 255, 0) 92.71%),
    url(${___CSS_LOADER_URL_REPLACEMENT_0___});

  background-size: cover;
  background-position: center;
}

.listItemStyle {
  list-style-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  padding-inline-start: 1ch;
}

@media only screen and (max-width: 670px) {
  .cyberParent {
    background: linear-gradient(
        90deg,
        #ffc641 0%,
        rgba(255, 255, 255, 0) 92.71%
      ),
      url(${___CSS_LOADER_URL_REPLACEMENT_2___});

    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 100% 0%;
    /* background-position: 100% 0%; */
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/cyberSecurity.css"],"names":[],"mappings":"AAAA;EACE;2CACyC;;EAEzC,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,yDAAsD;EACtD,yBAAyB;AAC3B;;AAEA;EACE;IACE;;;;;6CAK4C;;IAE5C,0BAA0B;IAC1B,4BAA4B;IAC5B,4BAA4B;IAC5B,kCAAkC;EACpC;AACF","sourcesContent":[".cyberParent {\n  background: linear-gradient(90deg, #ffc641 0%, rgba(255, 255, 255, 0) 92.71%),\n    url(\"../assets/svgs/cyberSecurity.jpg\");\n\n  background-size: cover;\n  background-position: center;\n}\n\n.listItemStyle {\n  list-style-image: url(../assets/icons/bulletsBlue.svg);\n  padding-inline-start: 1ch;\n}\n\n@media only screen and (max-width: 670px) {\n  .cyberParent {\n    background: linear-gradient(\n        90deg,\n        #ffc641 0%,\n        rgba(255, 255, 255, 0) 92.71%\n      ),\n      url(\"../assets/svgs/cyberSecurity-sm.png\");\n\n    background-size: 100% auto;\n    background-repeat: no-repeat;\n    background-position: 100% 0%;\n    /* background-position: 100% 0%; */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
