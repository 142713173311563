// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base styles for the descriptive box */
.descriptive-box {
  max-height: 0; /* Initially, the box is collapsed to a max height of 0 to hide its content */
  overflow: hidden; /* This prevents any part of the box's content from being visible outside its bounds */
  transition: max-height 0.7s ease-out; /* Animation for expanding/collapsing the box, lasting 0.2 seconds with an 'ease-out' effect */
}

/* Styles applied when the descriptive box is in the 'open' state */
.descriptive-box.open {
  max-height: 40vh; /* The maximum height is set to 40% of the viewport height, allowing the content to expand up to this size */
  overflow-y: auto; /* Enables vertical scrolling if the content exceeds the max height */
}

/* Styles for when the descriptive box is in the process of closing */
.descriptive-box.closing {
  max-height: 0; /* Resets the max height to 0, initiating the collapse animation back to the hidden state */
}
`, "",{"version":3,"sources":["webpack://./src/styles/accordian.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;EACE,aAAa,EAAE,6EAA6E;EAC5F,gBAAgB,EAAE,sFAAsF;EACxG,oCAAoC,EAAE,8FAA8F;AACtI;;AAEA,mEAAmE;AACnE;EACE,gBAAgB,EAAE,4GAA4G;EAC9H,gBAAgB,EAAE,qEAAqE;AACzF;;AAEA,qEAAqE;AACrE;EACE,aAAa,EAAE,2FAA2F;AAC5G","sourcesContent":["/* Base styles for the descriptive box */\n.descriptive-box {\n  max-height: 0; /* Initially, the box is collapsed to a max height of 0 to hide its content */\n  overflow: hidden; /* This prevents any part of the box's content from being visible outside its bounds */\n  transition: max-height 0.7s ease-out; /* Animation for expanding/collapsing the box, lasting 0.2 seconds with an 'ease-out' effect */\n}\n\n/* Styles applied when the descriptive box is in the 'open' state */\n.descriptive-box.open {\n  max-height: 40vh; /* The maximum height is set to 40% of the viewport height, allowing the content to expand up to this size */\n  overflow-y: auto; /* Enables vertical scrolling if the content exceeds the max height */\n}\n\n/* Styles for when the descriptive box is in the process of closing */\n.descriptive-box.closing {\n  max-height: 0; /* Resets the max height to 0, initiating the collapse animation back to the hidden state */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
