// Import React library along with useEffect and useState hooks
import React, { useEffect, useState } from "react";

// Import icons for the Accordian component
import plusIcon from "../assets/icons/plusIcon.svg";
import minusIcon from "../assets/icons/minusIcon.svg";

// Import styles specific to the Accordian component
import "../styles/accordian.css";

// Define the Accordian functional component
const Accordian = ({ isExpanded, onExpand, title, description, isLast }) => {
  // State to manage the closing animation
  const [closing, setClosing] = useState(false);

  // Effect to trigger closing animation when isExpanded becomes false
  useEffect(() => {
    if (!isExpanded) {
      // Set closing to true to trigger closing animation after a delay
      const timeout = setTimeout(() => {
        setClosing(true);
      }, 300); // Adjust the delay as needed to match the CSS transition duration
      return () => clearTimeout(timeout);
    }
  }, [isExpanded]);

  // Return the JSX structure for the Accordian component
  return (
    <div>
      <div
        className={`${
          isLast
            ? "border-y-[1px] border-y-[#11174833]"
            : "border-t-[1px] border-t-[#11174833]"
        } text-[#111748CC] text-[16px] tablet:text-[19px] laptop:text-[22px] tablet:ml-32 space-y-4`}
      >
        {/* Header of the Accordian with title and expand/collapse icon */}
        <div
          className="flex justify-between items-center font-[500] py-9 cursor-pointer text-[21px] tablet:text-[23px] laptop:text-[25px] "
          onClick={onExpand}
        >
          {title}
          <img src={isExpanded ? minusIcon : plusIcon} alt="" />
        </div>
        {/* Description box with dynamic classes for opening and closing animations */}
        <div
          className={`descriptive-box   ${
            isExpanded ? "open " : closing ? "closing " : ""
          }`}
        >
          <div className="px-2 pb-2 ">{description}</div>
        </div>
      </div>
    </div>
  );
};

// Export the Accordian component as the default export
export default Accordian;
