import React, { useEffect, useId, useState } from "react";
import elementIsInViewPort from "../functions/elementIsInViewPort";

const SectionTitle = ({
  id,
  className,
  firstChild,
  secondChild,
  firstChildTranslateAtStart,
  firstChildTranslateAtEnd,
  secondChildTranslateAtStart,
  secondChildTranslateAtEnd,
  viewPortStartPointFromBottom,
  viewPortEndPointFromBottom,
}) => {
  const generatedId = useId();
  const [percentage, setPercentage] = useState(0);
  const [fisrtChildTransLate, setFisrtChildTransLate] = useState(
    firstChildTranslateAtStart
  );
  // eslint-disable-next-line no-unused-vars
  const [secondChildTransLate, setSecondChildTransLate] = useState(
    secondChildTranslateAtStart
  );

  const elementId = id || generatedId;

  useEffect(() => {
    const handleScroll = () => {
      const elementIsInViewPortConst = elementIsInViewPort(
        elementId,
        viewPortEndPointFromBottom,
        viewPortStartPointFromBottom
      );
      setPercentage(elementIsInViewPortConst);
    };
    // Attach event listener for scrolling
    window.addEventListener("scroll", handleScroll);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let firstChildTransLateCurrent = null;
    if (firstChildTranslateAtStart > firstChildTranslateAtEnd) {
      firstChildTransLateCurrent =
        firstChildTranslateAtStart +
        (percentage / 100) *
          (firstChildTranslateAtEnd - firstChildTranslateAtStart);
    } else {
      firstChildTransLateCurrent =
        firstChildTranslateAtEnd +
        (percentage / 100) *
          (firstChildTranslateAtStart - firstChildTranslateAtEnd);
    }
    setFisrtChildTransLate(firstChildTransLateCurrent);

    let secondChildTransLateCurrent = null;
    if (secondChildTranslateAtStart > secondChildTranslateAtEnd) {
      secondChildTransLateCurrent =
        secondChildTranslateAtStart +
        (percentage / 100) *
          (secondChildTranslateAtEnd - secondChildTranslateAtStart);
    } else {
      secondChildTransLateCurrent =
        secondChildTranslateAtEnd +
        (percentage / 100) *
          (secondChildTranslateAtStart - secondChildTranslateAtEnd);
    }
    setSecondChildTransLate(secondChildTransLateCurrent);

    /*
    console.log(
      `First::\n\tfirstChildTranslateAtStart: ${firstChildTranslateAtStart},\n\tfirstChildTranslateAtEnd: ${firstChildTranslateAtEnd}\n\tfirstChildTransLateCurrent: ${firstChildTransLateCurrent}\n\nSecond:: \n\tsecondChildTranslateAtStart: ${secondChildTranslateAtStart},\n\tsecondChildTranslateAtEnd: ${secondChildTranslateAtEnd}\n\tsecondChildTransLateCurrent: ${secondChildTransLateCurrent}`
    );
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  return (
    <div id={elementId} className={className}>
      <div
        style={{
          translate: `${fisrtChildTransLate}px`,
        }}
      >
        {firstChild}
      </div>
      {/* <div
        style={{
          translate: `${secondChildTransLate}px`,
        }}
      >
        {secondChild}
      </div> */}
      <div
        style={{
          translate: `-${fisrtChildTransLate}px`,
        }}
      >
        {secondChild}
      </div>
    </div>
  );
};

export default SectionTitle;

SectionTitle.defaultProps = {
  id: null, 
  firstChildTranslateAtStart: 300,
  firstChildTranslateAtEnd: 0,
  secondChildTranslateAtEnd: 0,
  secondChildTranslateAtStart: -300,
  viewPortStartPointFromBottom: 0,
  viewPortEndPointFromBottom: 100,
};

// Formula : Value = LowerBound + (Percentage / 100) * (UpperBound - LowerBound)
