import React from "react";
import "../styles/resourceAugmentation.css";
import productivityBoostLogo from "../assets/svgs/resource-augmentation/productivity-boost.svg";
import techAdvancmentsLogo from "../assets/svgs/resource-augmentation/tech-advancments.svg";
import staffingFlexibilityLogo from "../assets/svgs/resource-augmentation/staffing-flexibility.svg";
import chipsLogo from "../assets/svgs/resource-augmentation/chips.svg";
import CustomizedTooltips from "./CustomizedTooltips";
import SectionTitle from "../reusable/SectionTitle";
import SectionSubTitle from "../reusable/SectionSubTitle";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";

const ResourceAugmentation = () => {
  return (
    <section
      id="resourceAugmentation"
      className="py-20 tablet:py-32 laptop:pt-[163px] laptop:pb-[251px] bg-[#111748] text-[#fff]"
    >
      <div className="flex justify-center tablet:text-center">
        <div className="mx-5 space-y-5 tablet:mx-[265px] leading-none">
          {/* <AnimateOnDisplay
            className={`uppercase text-[40px] tablet:text-[63px] laptop:text-[85px] desktop:text-[108px] font-[300]`}
            animationClass={"snap-in"}
          >
            <div>resource</div>
            <div className="font-[900]">augmentation</div>
          </AnimateOnDisplay> */}
          <SectionTitle
            className={`uppercase text-[40px] tablet:text-[63px] laptop:text-[85px] desktop:text-[108px] font-[300]`}
            firstChild={<div>resource</div>}
            secondChild={<div className="font-[900]">augmentation</div>}
            viewPortStartPointFromBottom={0}
            viewPortEndPointFromBottom={50}
            firstChildTranslateAtStart={window.innerWidth * 0.3}
          />
          <AnimateOnDisplay
            animationClass={"paragraph-animation"}
            screenPercentHeightToStartAnimation={90}
            className={"opacity-0"}
            animateEveryTime={false}
          >
            {" "}
            <div className="text-[#FFFFFF99] pt-1 text-[16px] tablet:text-[18px] laptop:text-[20px] desktop:text-[22px] mobile:leading-[34.82px] leading-[23.22px]">
              In today's fast-paced and ever-evolving landscape, staying
              competitive requires not just innovation but also the strategic
              allocation of resources. We present a solution that goes beyond
              the conventional – Resource Augmentation. Imagine unlocking the
              full potential of your organization by strategically enhancing
              your human, financial, and technological assets.
            </div>
          </AnimateOnDisplay>
        </div>
      </div>
      <div className="px-[22px] tablet:px-[87px] laptop:px-[152px] desktop:px-[217px] mt-[61px] tablet:mt-[78px] space-y-[52px] relative bg-gray-600-">
        {/* Icons Div */}
        <div className="absolute w-full h-full">
          <div className="relative h-full w-full">
            <div className="absolute top-[15%] tablet:top-0 left-[10%] tablet:left-[60%] laptop:left-[37%] desktop:left-[32%] translate-y-[30%] laptop:translate-y-[-20%] translate-x-[-50%]">
              <img
                className="beat-anim w-[84px] mobile:w-[100px] tablet:w-[129px]"
                src={productivityBoostLogo}
                alt="img"
              />
            </div>
            <div className="absolute right-[12%] tablet:right-[80%] laptop:right-[95%] top-[43%] mobile:top-[46%] tablet:top-[30%] laptop:top-[47%] translate-y-[-50%] tablet:translate-y-[-50%]">
              <img
                className="beat-anim w-[72px] mobile:w-[90px] tablet:w-[118px]"
                src={techAdvancmentsLogo}
                alt="img"
              />
            </div>
            <div className="absolute top-[85%] mobile:top-[90%] tablet:top-[57%] laptop:top-[49%] right-[12%] tablet:right-[23%] translate-y-[-50%]">
              <img
                className="beat-anim w-[118px] mobile:w-[150px] laptop:w-[200px]"
                src={staffingFlexibilityLogo}
                alt="img"
              />
            </div>
            <div className="absolute bottom-[30%] mobile:bottom-[27%] tablet:bottom-[20%] laptop:bottom-[5%] left-[11%] tablet:left-[10%] laptop:left-[38%] translate-x-[-50%] ">
              <img
                className="mt-[50px] beat-anim w-[80px]  tablet:w-[100px] laptop:w-[130px]"
                src={chipsLogo}
                alt="img"
              />
            </div>
          </div>
        </div>
        <div className="grid laptop:grid-cols-2 mt-[17px] space-y-16 tablet:space-y-[83px] laptop:mr-28">
          <SubSection
            title={"Productivity Boost:"}
            description={
              <>
                <AnimateOnDisplay
                  animationClass={"snap-in"}
                  className={"opacity-0"}
                  animateEveryTime={false}
                >
                  {" "}
                  <span className="hidden tablet:inline">
                    Our solution allows you to{" "}
                    <span className="font-[500] text-[#fff]">
                      scale your workforce and technology infrastructure
                    </span>{" "}
                    in alignment with your business needs. Whether you're
                  </span>
                </AnimateOnDisplay>
                <AnimateOnDisplay
                  animationClass={"snap-in"}
                  className={"opacity-0"}
                  animateEveryTime={false}
                >
                  {" "}
                  <span className="tablet:hidden">
                    Our solution allows you to{" "}
                    <span className="font-[500] text-[#fff]">
                      scale your workforce and technology infrastructure
                    </span>{" "}
                    in ali
                  </span>
                </AnimateOnDisplay>
              </>
            }
            note={
              "Our solution allows you to scale your workforce and technology infrastructure in alignment with your business needs. Whether you're facing a surge in demand or gearing up for a new project, our solution empowers you to optimize operations and enhance overall productivity."
            }
          />
          <div className=" flex justify-end bg-cyan-600- w-full">
            <SubSection
              className={""}
              title={"Staffing Flexibility:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="hidden tablet:inline">
                      Say goodbye to workforce constraints. With resource
                      augmentation, you can swiftly onboard skilled
                      professionals for short-term projects or
                    </span>{" "}
                  </AnimateOnDisplay>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="tablet:hidden">
                      Say goodbye to workforce constraints. With resource
                      augmentation, you can swiftly onboard skilled
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "Say goodbye to workforce constraints. With resource augmentation, you can swiftly onboard skilled professionals for short-term projects or peak seasons, ensuring that you have the right talent when you need it most. This flexibility enables you to adapt quickly to market changes and seize opportunities without the burden of long-term commitments."
              }
            />
          </div>
        </div>
        <div className="grid tablet:grid-cols-2">
          <div className="flex laptop:justify-end">
            <SubSection
              className={"mt-6"}
              title={"Tech Advancements:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="hidden tablet:inline">
                      Embrace the latest technologies without the hassle of
                      major investments. Our model allows you to integrate
                      cutting-edge solutions seamlessly
                    </span>
                  </AnimateOnDisplay>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    <span className="tablet:hidden">
                      Embrace the latest technologies without the hassle of
                      major investments. Our model allows you to
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "Embrace the latest technologies without the hassle of major investments. Our model allows you to integrate cutting-edge solutions seamlessly into your operations. Stay ahead of the curve and future-proof your business without compromising your budget."
              }
            />
          </div>
        </div>
        <div className="grid tablet:grid-cols-2  laptop:mr-28">
          <div className="order-2 tablet:order-1">
            <SubSection
              className="mt-16 tablet:mt-[150px]"
              title={"Agile Adaptation:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="hidden tablet:inline">
                      In today's dynamic business environment, adaptability is
                      key. Resource augmentation provides the agility to scale
                      up or down rapidly, ensuring that
                    </span>
                  </AnimateOnDisplay>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    <span className="tablet:hidden">
                      In today's dynamic business environment, adaptability is
                      key. Resource augmentation
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "In today's dynamic business environment, adaptability is key. Resource augmentation provides the agility to scale up or down rapidly, ensuring that you are always in sync with market demands. This nimble approach positions your organization for sustained growth and success."
              }
            />
          </div>
          <div className="flex justify-end order-1 tablet:order-2">
            <SubSection
              title={"Cost-Effective:"}
              description={
                <>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="hidden tablet:inline">
                      Offering a cost-efficient alternative to traditional
                      hiring or technology investments. By paying for resources
                      only when needed
                    </span>
                  </AnimateOnDisplay>
                  <AnimateOnDisplay
                    animationClass={"snap-in"}
                    className={"opacity-0"}
                    animateEveryTime={false}
                  >
                    {" "}
                    <span className="tablet:hidden">
                      Offering a cost-efficient alternative to traditional
                      hiring or technology investments. By payin
                    </span>
                  </AnimateOnDisplay>
                </>
              }
              note={
                "Offering a cost-efficient alternative to traditional hiring or technology investments. By paying for resources only when needed, you eliminate unnecessary overhead costs and maximize your return on investment. It's a smarter, leaner approach to resource management."
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResourceAugmentation;

const SubSection = ({ title, description, className, note }) => {
  return (
    <>
      <div
        className={`w-[257px] mobile:w-[400px] tablet:w-[450px] desktop:w-[499px] max-h-[167px] saoce-y-5 ${className}`}
      >
        {/* <div className="uppercase font-[900] text-[21px] tablet:text-[27px] laptop:text-[33px] desktop:text-[39px] leading-normal">
          {title}
        </div> */}
        <div className="uppercase font-[900] text-[20px] tablet:text-[27px] laptop:text-[33px] desktop:text-[39px] leading-normal">
          <SectionSubTitle text={title} charBgColor={"#111748"} />
        </div>
        <div className="text-[16px] tablet:text-[18px] laptop:text-[20px] desktop:text-[22px] text-[#FFFFFF99]">
          {description} ...
          <CustomizedTooltips note={note} />
        </div>
      </div>
    </>
  );
};
