import React, { useState } from "react";
import "../styles/cyberSecurity.css";
//import cyberSecurity from "../assets/svgs/cyberSecurity.svg";
import Accordian from "./Accordian";
//import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import SectionTitle from "../reusable/SectionTitle";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
// import SectionSubTitle from "../reusable/SectionSubTitle";

const CyberSecurity = () => {
  const [expandedAccordianNo, setExpandedAccordianNo] = useState(0);
  const handleOnExpand = (index) => {
    if (expandedAccordianNo === index) {
      setExpandedAccordianNo(-1);
    } else {
      setExpandedAccordianNo(index);
    }
  };
  const stats = [
    "In 2021, 43% of cyber attacks targeted small businesses.",
    "Ransomware attacks have increased by 365% since 2018.",
    "In 2021, the average cost of a data breach was $3.86 million.",
    "95% of data breaches happen due to human error.",
    "With over 350,000 new malware samples reported daily, the cybersecurity industry battles an ever-expanding array of threats.",
    "94% of organizations believe compliance with data protection regulations is a top priority, creating a substantial market for compliant cybersecurity solutions.",
    "Remote work adoption has led to a 600% increase in cyber attacks, underscoring the need for cybersecurity solutions tailored to the new work landscape.",
  ];

  return (
    <section
      id="cyberSecurity"
      className="py-[110px] min-h-[100vh] px-[66px] cyberParent"
    >
      <div className="tablet:flex ">
        <div className="tablet:w-[30vw]">
          {/* <AnimateOnDisplay
            animationClass={"snap-in"}
            className={`space-y-[20px] uppercase laptop:text-[108px] tablet:text-[60px] leading-none text-[50px]  text-[#111748]`}
          >
            <div className=" font-[300]">Cyber</div>
            <div className="font-[900]">Security</div>
          </AnimateOnDisplay> */}
          <SectionTitle
            className={`space-y-[20px] uppercase laptop:text-[108px] tablet:text-[60px] leading-none text-[50px]  text-[#111748]`}
            firstChild={<div className=" font-[300]">Cyber</div>}
            secondChild={<div className="font-[900]">Security</div>}
            viewPortStartPointFromBottom={0}
            viewPortEndPointFromBottom={50}
            firstChildTranslateAtStart={window.innerWidth * 0.2}
          />
          <p className="text-[#111748CC] mt-4 text-[21px] tablet:text-[22px] laptop:text-[25px] leading-none font-[500]">
            Facts Check
          </p>
          {/* <SectionSubTitle
            text={"Facts Check"}
            className={
              "text-[#111748CC] mt-4 text-[21px] tablet:text-[22px] laptop:text-[25px] leading-none font-[500]"
            }
          /> */}
          <ul
            className="text-[#111748CC] text-[16px] tablet:text-[19px] laptop:text-[22px] my-[20px] space-y-4"
            style={{ listStyleType: "none", paddingLeft: "16px" }}
          >
            {stats.map((stat, index) => (
              <AnimateOnDisplay
                key={index}
                animationClass={"snap-in"}
                screenPercentHeightToStartAnimation={90}
                className={"opacity-0"}
                animateEveryTime={false}
              >
                <li className="listItemStyle">{stat}</li>
              </AnimateOnDisplay>
            ))}
          </ul>
        </div>
        <div className="">
          {accordianList.map((accordianItem, i) => (
            <Accordian
              key={i}
              isLast={i === accordianList.length - 1}
              isExpanded={expandedAccordianNo === i}
              onExpand={() => handleOnExpand(i)}
              title={accordianItem.title}
              description={accordianItem.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CyberSecurity;

const accordianDescriptionHeadingClass =
  "font-[500] text-[19px] tablet:text-[20px] laptop:text-[21px]";

const accordianList = [
  {
    title: "Penetration Testing",
    description: (
      <div>
        Penetration testing, also known as pen testing, is a simulated cyber
        attack on a computer system, network, or web application to evaluate its
        security. The goal of this testing is to identify vulnerabilities and
        assess the overall security posture of the target, with the aim of
        improving it. <br />
        <br /> Penetration testers use various techniques, including automated
        scans and manual exploration, to try to find and exploit
        vulnerabilities. This helps to identify weaknesses that might be missed
        by other security measures, such as firewalls and antivirus software.
        The results of the penetration test can then be used to develop
        remediation plans and to improve the security of the target.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Here are 5 reasons why penetration testing for your organization is
          important:
        </span>
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>Compliance: </span>
        Certain laws and regulations, such as the GDPR, Payment Card Industry
        Data Security Standard (PCI DSS) and the Health Insurance Portability
        and Accountability Act (HIPAA), require organizations to regularly
        assess their security posture. Penetration testing can help
        organizations to meet these requirements and demonstrate their
        commitment to security.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>Liability:</span> By
        conducting regular penetration testing, organizations can identify and
        mitigate potential security risks before they can be exploited by
        attackers. This can help to reduce the risk of data breaches, which can
        result in significant financial losses, reputational damage, and legal
        liability.  
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>Contracts:</span> In
        some cases, organizations may be required to conduct penetration testing
        as a requirement of a contract, such as a service-level agreement (SLA)
        with a cloud provider. Penetration testing can help organisations to
        demonstrate that they are meeting their obligations under these
        agreements. 
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Due Diligence:
        </span>{" "}
        In the context of mergers and acquisitions, organizations may be
        required to conduct penetration testing as part of their due diligence
        process. This can help to identify potential security risks that could
        impact the value of the transaction.  
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>Evidence:</span> In
        the event of a data breach or other security incident, organizations may
        be required to provide evidence of the steps they took to secure their
        systems and infrastructure. Penetration testing can help to provide this
        evidence and demonstrate that the organization took reasonable steps to
        protect its systems and data.   
        <br />
        <br />
        In conclusion, from a legal perspective, penetration testing can help
        organizations to meet regulatory requirements, reduce their liability,
        fulfill contract obligations, demonstrate due diligence, and provide
        evidence of their security efforts.
      </div>
    ),
  },
  {
    title: "Security Audits",
    description: (
      <div>
        A security audit is a comprehensive assessment of an organization's
        security posture. The purpose of a security audit is to identify
        potential security risks and vulnerabilities, evaluate the effectiveness
        of existing security measures, and make recommendations for improving
        the overall security of the organization.
        <br />
        <br /> A security audit typically involves a detailed examination of an
        organization's systems, networks, applications, and infrastructure. This
        can include a review of security policies, procedures, and technical
        controls, as well as testing and validation of these controls to ensure
        that they are effective. The results of a security audit provide
        organizations with a clear understanding of their security posture,
        including any areas of weakness or vulnerability. This information can
        be used to make informed decisions about investments in security
        technology and services, as well as improvements to security policies
        and procedures. Overall, security consist of the following services:
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Vulnerability Assessment:{" "}
        </span>
        A systematic examination of an organization's systems and infrastructure
        to identify potential security vulnerabilities.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Compliance Assessment:
        </span>{" "}
        An evaluation of an organization's compliance with relevant laws and
        regulations, including data privacy and security regulations.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Incident Response Assessment:
        </span>{" "}
        An evaluation of an organization's incident response capabilities,
        including its procedures and response time.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Social Engineering Assessment:
        </span>{" "}
        A simulation of social engineering attacks, such as phishing or baiting,
        to evaluate an organization's security awareness and response
        capabilities
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Source Code Review:
        </span>{" "}
        Code review is a process in which software code is reviewed and
        evaluated for security vulnerabilities and potential risks in order to
        mitigate it
      </div>
    ),
  },
  {
    title: "Ransomware",
    description: (
      <div>
        Ransomware is malware designed to deny a user or organization access to
        files on their computer. By encrypting these files and demanding a
        ransom payment for the decryption key, cyberattackers place
        organizations in a position where paying the ransom may be the easiest
        way to regain access to their files. <br />
        <br /> Ransomware may also threaten to publish personal data or
        permanently block access to it unless a ransom is paid.
        <br /> This malware is often spread through phishing emails that contain
        malicious attachments or through drive-by downloading which occurs when
        a user unknowingly visits an infected website and then malware is
        downloaded and installed without the user’s knowledge
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Ransomware Consequences:{" "}
        </span>
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>High Costs</span>
        <br />
        According to Forbes, In 2019, average remediation costs for a company
        that had incurred a ransomware attack were over $761,000. In 2020, the
        figure had more than doubled to $1.85 million. In just the United
        States, ransomware victims spent an average of $2.09 million on
        remediation costs.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Low Chances of Data Recovery
        </span>
        <br />
        Paying a ransom does not guarantee that all data will be recovered.
        Forbes reported that n average, only 65% of data is restored. 
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Brand Reputation Damage
        </span>
        <br />
        Ransomware attacks can be costly not only financially but also in
        corporate productivity and data breaches to customers. Releasing
        sensitive and personal data can be devastating to a company’s
        reputation. 
        <br />
        <br />
      </div>
    ),
  },
  {
    title: "GDPR",
    description: (
      <div>
        The General Data Protection and Regulation, or GDPR, is a legal
        framework containing guidelines for the collection of data of EU
        citizens.  <br />
        <br /> The GDPR exists as a framework for laws across the European
        continent. The legal framework ensures better protection and rights to
        individual EU citizens. The GDPR  regulates how businesses and other
        organizations handle user and client data. The GDPR will impose harsh
        fines against anyone who violates its privacy and security standards.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Data Protection Principles of the GDPR{" "}
        </span>
        <br />
        Processing must be lawful, fair, and transparent to the data subject. 
        <br />
        The data must be for a legitimate purpose specified to the data subject
        when it was collected.
        <br />
        Collect only minimal data as absolutely necessary for the purposes
        specified.
        <br /> Personal data must be kept accurate and up-to-date. 
        <br />
        Personally identifying data should have a storage limitation for as long
        as necessary for the specified purpose.
        <br />
        Processing of data must be done in such a way as to insure security, 
        integrity, and confidentiality (e.g. by using encryption).
        <br /> The data controller must show accountability for being able to
        demonstrate GDPR compliance with all these principles.
        <br />
        <br />
        <span className={accordianDescriptionHeadingClass}>
          Who does the GDPR apply to?{" "}
        </span>
        <br />
        Non-EU organizations must comply with the GDPR if they are offering
        goods and services via the internet. If a company is not in the EU but
        caters to EU customers, the the company should be GDPR compliant.
        <br />
        <br />
        If an organization uses web tools to track cookies or IP addresses of
        people who visit the website from EU countries, that organization must
        be GDPR compliant. 
        <br />
      </div>
    ),
  },
];
