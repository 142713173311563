// Import React library to use React components
import React, { useEffect } from "react";

// Import individual sections/components for the LandingPage
import Home from "../components/Home";
import MobileOperators from "../components/MobileOperators";
import EnterpriseSolutions from "../components/EnterpriseSolutions";
import SoftwareSolutions from "../components/SoftwareSolutions";
import PaymentSolutions from "../components/PaymentSolutions";
import CyberSecurity from "../components/CyberSecurity";
import ResourceAugmentation from "../components/ResourceAugmentation";
import LetsChat from "../components/LetsChat";

// Define the LandingPage functional component
const LandingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // Create a section for the entire landing page content
    <section className="w-full overflow-x-clip">
      {/* Render each component for different sections of the landing page */}
      <Home />
      <MobileOperators />
      <EnterpriseSolutions />
      <SoftwareSolutions />
      <PaymentSolutions />
      <CyberSecurity />
      <ResourceAugmentation />
      <LetsChat />
    </section>
  );
};

// Export the LandingPage component as the default export
export default LandingPage;
