import React from "react";
const AnimateEachLetter = ({
  text,
  charMappingFunction,
  className,
  baseAnimationDelay,
  animationLetterDelay,
  animationClass,
  animateNow,
  charClass,
  charDisplay,
}) => {
  if (charMappingFunction) {
    return (
      <div className={className}>
        {text.split("").map((character, index) => {
          return charMappingFunction(character, index);
        })}
      </div>
    );
  } else {
    return (
      <div className={className}>
        {text.split("").map((character, index) => {
          return (
            <div
              key={index}
              className={`${charClass} ${animateNow ? animationClass : ""}`}
              style={{
                display: charDisplay,
                animationDelay:
                  `${baseAnimationDelay + animationLetterDelay * index}s` ||
                  "0s",
              }}
            >
              {character === " " ? "\u00A0" : character}
            </div>
          );
        })}
      </div>
    );
  }
};

export default AnimateEachLetter;

AnimateEachLetter.defaultProps = {
  className: "",
  subParentClass: "",
  animationLetterDelay: 0.05,
  baseAnimationDelay: 0,
  animateNow: true,
  animationClass: "",
  charClass: "",
  charDisplay: "inline-block",
};
