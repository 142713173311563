// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the paragraph text */
.paragraph-text {
  animation-timeline: view(block 60% 10%);
  /* This property isn't standard CSS and might not work as intended. 
    It seems to be an attempt to control the animation's play state based on scroll position, 
    but standard CSS doesn't support 'animation-timeline' or 'view()' function. */

  animation-name: line-height-anim;
  /* Refers to the keyframes animation named 'line-height-anim' */
  animation-fill-mode: both;
  /* The animation's styles apply before and after the animation plays */
  animation-duration: 1s;
  /* The total duration of the animation is 1 second */
  animation-timing-function: linear;
  /* The animation pace is constant from start to end */
}

/* Keyframes for the line-height animation */
@keyframes line-height-anim {
  0% {
    line-height: 3em;
    /* At the start (0%), the line-height is 3 times the size of the current font size */
  }

  35% {
    line-height: 2em;
    /* At 35% of the animation duration, line-height transitions to 2em */
  }

  100% {
    line-height: 1.5em;
    /* By the end (100%), the line-height settles at 1.5em */
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/enterpriseSolutions.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,uCAAuC;EACvC;;iFAE+E;;EAE/E,gCAAgC;EAChC,+DAA+D;EAC/D,yBAAyB;EACzB,sEAAsE;EACtE,sBAAsB;EACtB,oDAAoD;EACpD,iCAAiC;EACjC,qDAAqD;AACvD;;AAEA,4CAA4C;AAC5C;EACE;IACE,gBAAgB;IAChB,oFAAoF;EACtF;;EAEA;IACE,gBAAgB;IAChB,qEAAqE;EACvE;;EAEA;IACE,kBAAkB;IAClB,wDAAwD;EAC1D;AACF","sourcesContent":["/* Styles for the paragraph text */\n.paragraph-text {\n  animation-timeline: view(block 60% 10%);\n  /* This property isn't standard CSS and might not work as intended. \n    It seems to be an attempt to control the animation's play state based on scroll position, \n    but standard CSS doesn't support 'animation-timeline' or 'view()' function. */\n\n  animation-name: line-height-anim;\n  /* Refers to the keyframes animation named 'line-height-anim' */\n  animation-fill-mode: both;\n  /* The animation's styles apply before and after the animation plays */\n  animation-duration: 1s;\n  /* The total duration of the animation is 1 second */\n  animation-timing-function: linear;\n  /* The animation pace is constant from start to end */\n}\n\n/* Keyframes for the line-height animation */\n@keyframes line-height-anim {\n  0% {\n    line-height: 3em;\n    /* At the start (0%), the line-height is 3 times the size of the current font size */\n  }\n\n  35% {\n    line-height: 2em;\n    /* At 35% of the animation duration, line-height transitions to 2em */\n  }\n\n  100% {\n    line-height: 1.5em;\n    /* By the end (100%), the line-height settles at 1.5em */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
