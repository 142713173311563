import React, { useState } from "react";
import "../styles/enterpriseSolutions.css";
//importing Images for services
import image01 from "../assets/svgs/enterprise-solution-svgs/image01.jpg";
import image02 from "../assets/svgs/enterprise-solution-svgs/image02.jpg";
import image03 from "../assets/svgs/enterprise-solution-svgs/image03.jpg";
import image04 from "../assets/svgs/enterprise-solution-svgs/image04.jpg";
import image05 from "../assets/svgs/enterprise-solution-svgs/image05.jpg";
import image06 from "../assets/svgs/enterprise-solution-svgs/image06.jpg";
import image07 from "../assets/svgs/enterprise-solution-svgs/image07.jpg";
import image08 from "../assets/svgs/enterprise-solution-svgs/image08.jpg";
import image09 from "../assets/svgs/enterprise-solution-svgs/image09.jpg";
import image10 from "../assets/svgs/enterprise-solution-svgs/image10.jpg";
import image11 from "../assets/svgs/enterprise-solution-svgs/image11.jpg";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import ShakingBtn from "./animation/ShakingBtn";
import ImageWithAnimation from "./animation/ImageWithAnimation";
import SectionSubTitle from "../reusable/SectionSubTitle";
import HorizontalBorderWithnDisplayAnimation from "../reusable/HorizontalBorderWithnDisplayAnimation";

const EnterpriseSolutions = () => {
  const noOfComponentsToShowWhenCompressed = 4;
  const [isExpanded, setIsExpanded] = useState(false);
  const expandClicked = () => {
    setIsExpanded(true);
  };
  const compressClicked = () => {
    setIsExpanded(false);

    const element = document.getElementById(
      "lastEnterpriseSolutionWhenCompressed"
    );
    const rect = element.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const targetY = rect.top + scrollTop;

    window.scrollTo({
      top: targetY,
      behavior: "smooth",
    });
  };

  return (
    <section id="enterpriseSolutions" className="bg-[#fff] text-[#111748]">
      <div className="pt-[5.1875em] pb-8 mobile:pb-12 tablet:pb-16 laptop:pb-32 space-y-3 tablet:space-y-6 laptop:space-y-9 desktop:space-y-12">
        <AnimateOnDisplay
          animationClass={"snap-in-"}
          className={`flex justify-center items-center`}
        >
          <div
            className={`-text-[40px] -tablet:text-[62.67px] -laptop:text-[85.33px] -desktop:text-[108px] font-40-to-108 enterprise-solution-section-title-animation uppercase leading-none`}
          >
            <div className="text-[#111748] text-left font-[300] bg-yellow-600-">
              Enterprise
            </div>
            <div className="text-[#FFC641] text-right font-[900] bg-green-600-">
              Solutions
            </div>
          </div>
        </AnimateOnDisplay>
        <AnimateOnDisplay
          animationClass={"paragraph-animation"}
          screenPercentHeightToStartAnimation={90}
          className={"opacity-0"}
          animateEveryTime={false}
        >
          <p className="text-[16px] tablet:text-[22px] laptop:text-[28px] desktop:text-[34px] font-[200] text-center mx-[10%] laptop:mx-[13.33%]">
            At PlanetBeyond,
            <span className="font-[500]">
              {" "}
              we are committed to equipping businesses
            </span>{" "}
            with the tools they need to thrive in a rapidly evolving digital
            landscape. Our Enterprise Solutions are designed to address the{" "}
            <span className="font-[500]">
              unique challenges and opportunities that organizations
            </span>{" "}
            face in their pursuit of excellence.
          </p>
        </AnimateOnDisplay>
      </div>
      <div className="border-t-[1px]- border-[#11174833] text-[#111748]">
        <HorizontalBorderWithnDisplayAnimation borderColor={"#11174833"} />
        {servicesList.map(
          (service, i) =>
            (isExpanded || i < noOfComponentsToShowWhenCompressed) && (
              <div key={i}>
                <div
                  className="px-[5vw] tablet:px-16 py-[31px] min-h-[212px] flex flex-col-reverse gap-[21px] tablet:flex-row items-center justify-between border-b-[1px]- border-[#11174833]-"
                  id={
                    i === noOfComponentsToShowWhenCompressed - 1
                      ? "lastEnterpriseSolutionWhenCompressed"
                      : `enterpriseSolution${i}`
                  }
                >
                  <div className="flex justify-center items-center w-full ">
                    <div className="flex justify-center tablet:justify-normal items-baseline ">
                      <div className="w-[37px] tablet:w-[5.625em]">
                        {service.no}
                      </div>
                      <div className="max-w-[80%]">
                        {/* <p className="font-[900] text-[21px] tablet:text-[27.33px] laptop:text-[33.67px] desktop:text-[40px] uppercase leading-snug">
                        {service.title}
                      </p> */}
                        <SectionSubTitle
                          className={
                            "font-[900] text-[21px] tablet:text-[27.33px] laptop:text-[33.67px] desktop:text-[40px] uppercase leading-snug"
                          }
                          text={service.title}
                        />
                        <AnimateOnDisplay
                          animationClass={"paragraph-animation"}
                          screenPercentHeightToStartAnimation={90}
                          className={"opacity-0"}
                          animateEveryTime={false}
                        >
                          {" "}
                          <p className="font-[300] text-[16px] tablet:text-[22px] tablet:hidden">
                            <ExpandableText
                              noOfCharsWhenCompressed={134}
                              text={service.description}
                            />
                          </p>
                        </AnimateOnDisplay>
                        <AnimateOnDisplay
                          animationClass={"paragraph-animation"}
                          screenPercentHeightToStartAnimation={90}
                          className={"opacity-0"}
                          animateEveryTime={false}
                        >
                          {" "}
                          <p className="font-[300] text-[21px] tablet:text-[22px] hidden tablet:block leading-snug">
                            {service.description}
                          </p>
                        </AnimateOnDisplay>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center item-center w-full tablet:w-auto">
                    <div
                      className="min-w-[90vw] max-w-[90vw] min-h-[239px]  
                    mobile:min-w-[450px] mobile:min-h-[300px] mobile:max-w-[450px] mobile:max-h-[300px] 
                tablet:min-w-[17.5em] tablet:min-h-[10.9375em] tablet:max-w-[17.5em] tablet:max-h-[10.9375em] "
                    >
                      <ImageWithAnimation
                        src={service.img}
                        animatingDivHeight={"400px"}
                      />
                      {/* <img
                      className="w-full h-full object-contain"
                      src={service.img}
                      alt="not found"
                    /> */}
                    </div>
                  </div>
                </div>
                <HorizontalBorderWithnDisplayAnimation
                  borderColor={"#11174833"}
                />
              </div>
            )
        )}
      </div>
      <div className="pt-[75px] pb-[156px] flex justify-center ">
        {isExpanded ? (
          <ShakingBtn>
            {" "}
            <button onClick={compressClicked} className={expandBtnClass}>
              Collapse All
            </button>
          </ShakingBtn>
        ) : (
          <ShakingBtn>
            {" "}
            <button onClick={expandClicked} className={expandBtnClass}>
              Expand All
            </button>
          </ShakingBtn>
        )}
      </div>
    </section>
  );
};

const ExpandableText = ({ noOfCharsWhenCompressed, text }) => {
  const [isCompressed, setIsCompressed] = useState(true);
  const seeMoreClicked = () => {
    setIsCompressed(false);
  };
  const seeLessClicked = () => {
    setIsCompressed(true);
  };
  const textToShow = isCompressed
    ? text.slice(0, noOfCharsWhenCompressed) + "..."
    : text + ". ";
  const btn = isCompressed ? (
    <button className="text-[#111748] font-[500]" onClick={seeMoreClicked}>
      Read More
    </button>
  ) : (
    <button className="text-[#111748] font-[500]" onClick={seeLessClicked}>
      Read Less
    </button>
  );
  return (
    <>
      {textToShow}
      {btn}
    </>
  );
};

export default EnterpriseSolutions;

const expandBtnClass =
  "border-[1px] border-[#11174833] rounded-[52px] text-[1em] text-center content-center px-7 py-3";

const servicesList = [
  {
    no: "01",
    title: "Corporate Call & SMS",
    description:
      "Corporate Calls & SMS services offered by Planet Beyond are designed to cater to the communication needs of businesses and enterprises. It provides the customized solutions for the businesses to cater their requirements",
    img: image01,
  },
  {
    no: "02",
    title: "Mobile marketing platform",
    description:
      "A comprehensive suite of tools and solutions to help businesses reach and engage their customers effectively through mobile devices. From SMS Marketing, which allows for targeted and personalized messaging, to Mobile App Marketing, which helps in user acquisition and engagement through targeted ads and push notifications, these platforms cater to different aspects of mobile marketing",
    img: image02,
  },
  {
    no: "03",
    title: "Data Analytics",
    description:
      "Our advanced data analytics tools provide valuable insights into customer behavior, market trends, and business operations, allowing companies to make informed decisions that drive growth and efficiency. Whether it's analyzing customer demographics, predicting market trends, or optimizing supply chain management, our data analytics services equip businesses with the tools they need to thrive in today's data-driven world.",
    img: image03,
  },
  {
    no: "04",
    title: "Loyalty management solution",
    description:
      "Loyalty Management Solution is a comprehensive platform designed to help businesses build and maintain loyal customer relationships. With features such as customizable loyalty programs, customer segmentation, and personalized rewards, our solution enables businesses to increase customer retention, drive repeat purchases, and boost overall customer satisfaction.",
    img: image04,
  },
  {
    no: "05",
    title: "Adtech & Omni-channel communications",
    description:
      "Our suite of services includes advanced advertising technology that enables businesses to target their ads to the right audience, at the right time, and on the right platform. With features such as programmatic advertising, dynamic ad creation, and cross-channel tracking, our AdTech solutions help businesses maximize their advertising ROI and drive growth.",
    img: image05,
  },
  {
    no: "06",
    title: "Private LTE",
    description:
      "Our Private LTE networks offer superior coverage, capacity, and reliability compared to traditional Wi-Fi or public cellular networks, making them ideal for a wide range of applications, including industrial IoT, smart cities, remote offices, and more.",
    img: image06,
  },
  {
    no: "07",
    title: "E-KYC Solution",
    description:
      "Planet Beyond's E-KYC (Know Your Customer) solution revolutionizes the customer onboarding process by enabling businesses to verify their customers' identities digitally, quickly, and securely. With features such as automated identity verification, facial recognition, and document authentication, our solution ensures compliance with regulatory requirements while enhancing the customer experience.",
    img: image07,
  },
  {
    no: "08",
    title: "Session Boarder Controller (SBC)",
    description:
      "Our SBCs are designed to enhance the security, reliability, and interoperability of voice, video, and messaging services across IP networks. With features such as firewall traversal, NAT (Network Address Translation) traversal, encryption, and QoS (Quality of Service) controls, our SBC solutions ensure secure and seamless communication between different network domains.",
    img: image08,
  },
  {
    no: "09",
    title: "Online business growth",
    description:
      "Planet Beyond's Online Business Growth services are designed to help businesses thrive in today's digital economy. From developing a strong online presence to implementing effective digital marketing strategies, our services are tailored to meet the diverse needs of businesses in various industries. Our solutions include website development and optimization, search engine optimization (SEO), social media marketing, content marketing, email marketing, and more.",
    img: image09,
  },
  {
    no: "10",
    title: "Call center solution",
    description:
      "Our Call Center solution enables businesses to enhance their overall customer service performance. With features such as intelligent call routing, IVR (Interactive Voice Response), call recording, and real-time analytics, our solution empowers businesses to deliver exceptional customer experiences and drive positive outcomes.",
    img: image10,
  },
  {
    no: "11",
    title: "Spam guard",
    description:
      "By implementing Planet Beyond's Spam Guard solution, businesses can safeguard their email communications, reduce the risk of data breaches, and protect their brand reputation. With features such as real-time threat intelligence, customizable filters, and automated response actions, our solution ensures that your email communications are secure and protected from cyberthreats.",
    img: image11,
  },
];
