import React from "react";
import "../../styles/animation.css";

const ShakingBtn = ({ className, children }) => {
  return (
    <div className={`shaking-btn-parent ${className}`}>
      <div className="shaking-btn-child">{children}</div>
    </div>
  );
};

export default ShakingBtn;
ShakingBtn.defaultProps = {
  className: "",
};
