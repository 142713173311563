import React from "react";
import AnimateOnDisplay from "./AnimateOnDisplay";

const HorizontalBorderWithnDisplayAnimation = ({
  trakingId,
  heightClass,
  borderColor,
  directionRightToLeft,
}) => {
  return (
    <AnimateOnDisplay
      animateEveryTime={false}
      id={trakingId}
      className={`w-full flex ${directionRightToLeft ? "justify-end" : ""}`}
      animationClass={"border-full"}
    >
      <div
        className={`${heightClass} border-div`}
        style={{ backgroundColor: borderColor }}
      />
    </AnimateOnDisplay>
  );
};

export default HorizontalBorderWithnDisplayAnimation;

HorizontalBorderWithnDisplayAnimation.defaultProps = {
  trakingId: null,
  heightClass: "h-[1px]",
  borderColor: "#fff",
  directionRightToLeft: false,
};
