// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Styles for the left border or divider */
#borderDivLeft {
  color: rgba(
    255,
    255,
    255,
    1
  ); /* Sets the text color to white with full opacity */
  margin-left: -22px; /* Positions the element 22 pixels to the left of its normal position */
  font-weight: 900; /* Sets the font weight to the boldest available (900) */
  text-shadow: 1px 0 #11174814, 0 -1px #11174814, -1px 0 #11174814,
    0 1px #11174814; /* Applies a subtle text shadow in a solid color with low opacity (#11174814) */
}

/* Styles for the right border or divider */
#borderDivRight {
  color: rgba(
    255,
    255,
    255,
    1
  ); /* Sets the text color to white with full opacity */
  margin-right: -35px; /* Positions the element 35 pixels to the right of its normal position */
  font-weight: 900; /* Sets the font weight to the boldest available (900) */
  text-shadow: 1px 0 #11174814, 0 -1px #11174814, -1px 0 #11174814,
    0 1px #11174814; /* Applies a subtle text shadow in a solid color with low opacity (#11174814) */
}

/* Responsive font size for section titles */
.section-title-font {
  font-size: clamp(2.5rem, 0.976rem + 5.6971vw, 7.8125rem);
  /* 
  The clamp function is used here to provide a flexible font size that grows with the viewport width.
  - Minimum size: 2.5rem
  - Preferred size: Scales with the viewport width, starting at 0.976rem and increasing by 5.6971vw.
  - Maximum size: 7.8125rem
  */
}
`, "",{"version":3,"sources":["webpack://./src/styles/letsChat.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;EACE;;;;;GAKC,EAAE,mDAAmD;EACtD,kBAAkB,EAAE,uEAAuE;EAC3F,gBAAgB,EAAE,wDAAwD;EAC1E;mBACiB,EAAE,+EAA+E;AACpG;;AAEA,2CAA2C;AAC3C;EACE;;;;;GAKC,EAAE,mDAAmD;EACtD,mBAAmB,EAAE,wEAAwE;EAC7F,gBAAgB,EAAE,wDAAwD;EAC1E;mBACiB,EAAE,+EAA+E;AACpG;;AAEA,4CAA4C;AAC5C;EACE,wDAAwD;EACxD;;;;;GAKC;AACH","sourcesContent":["/* Styles for the left border or divider */\n#borderDivLeft {\n  color: rgba(\n    255,\n    255,\n    255,\n    1\n  ); /* Sets the text color to white with full opacity */\n  margin-left: -22px; /* Positions the element 22 pixels to the left of its normal position */\n  font-weight: 900; /* Sets the font weight to the boldest available (900) */\n  text-shadow: 1px 0 #11174814, 0 -1px #11174814, -1px 0 #11174814,\n    0 1px #11174814; /* Applies a subtle text shadow in a solid color with low opacity (#11174814) */\n}\n\n/* Styles for the right border or divider */\n#borderDivRight {\n  color: rgba(\n    255,\n    255,\n    255,\n    1\n  ); /* Sets the text color to white with full opacity */\n  margin-right: -35px; /* Positions the element 35 pixels to the right of its normal position */\n  font-weight: 900; /* Sets the font weight to the boldest available (900) */\n  text-shadow: 1px 0 #11174814, 0 -1px #11174814, -1px 0 #11174814,\n    0 1px #11174814; /* Applies a subtle text shadow in a solid color with low opacity (#11174814) */\n}\n\n/* Responsive font size for section titles */\n.section-title-font {\n  font-size: clamp(2.5rem, 0.976rem + 5.6971vw, 7.8125rem);\n  /* \n  The clamp function is used here to provide a flexible font size that grows with the viewport width.\n  - Minimum size: 2.5rem\n  - Preferred size: Scales with the viewport width, starting at 0.976rem and increasing by 5.6971vw.\n  - Maximum size: 7.8125rem\n  */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
