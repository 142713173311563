import React from "react";
import "../styles/softwareSolutions.css";
import SoftwareSolutionsCardsSection from "./SoftwareSolutionsCardsSection";
import AnimateOnDisplay from "../reusable/AnimateOnDisplay";
import SectionSubTitle from "../reusable/SectionSubTitle";

const SoftwareSolutions = () => {
  return (
    <section id="softwareSolutions">
      <div className=" laptop:py-[105px] tablet:py-[90px] mobile:py-[75px] py-[65px]  bg-[#ECECF0] laptop:flex laptop:flex-row  flex flex-col gap-[77px]">
        <div className="px-6 mobile:px-0 laptop:space-y-14 tablet:space-y-12 mobile:space-y-10 space-y-8 text-[#111748] leading-none ">
          <div className="space-y-4">
            <div className="flex justify-center w-full laptop:w-[35vw]">
              <div
                className={`-laptop:text-[108px] -tablet:text-[70px] -text-[40px] font-40-to-108 uppercase software-solution-section-title-animation`}
              >
                <div className="font-[300] text-left">Software</div>
                <div className="font-[900] text-right">Services</div>
              </div>
            </div>
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {" "}
              <div className="laptop:pl-16 tablet:pl-12 mobile:pl-8 opacity-60 laptop:text-[22px] tablet:text-[19px] text-[16px] text-[#111748bd]  leading-[25.06px] laptop:max-w-[30.818em] max-w-[60rem]">
                PlanetBeyond is a dynamic and forward-thinking software services
                provider committed to delivering cutting-edge solutions to
                clients worldwide. With a relentless focus on innovation,
                quality, and customer satisfaction, we stand as a trusted
                partner for businesses seeking to leverage the power of
                technology for their growth.
              </div>
            </AnimateOnDisplay>
          </div>
          <div className="space-y-4 laptop:pl-16 tablet:pl-12 mobile:pl-8">
            {/* <div className="font-[900] laptop:text-[39px] tablet:text-[34px] mobile:text-[27px] text-[21px] uppercase">
              Global Presence
            </div> */}
            <SectionSubTitle
              className={
                "font-[900] laptop:text-[39px]- tablet:text-[34px]- mobile:text-[27px]- text-[21px]- font-21-to-40 uppercase"
              }
              text={"Global Presence"}
              charBgColor={"#ECECF0"}
            />
            <AnimateOnDisplay
              animationClass={"paragraph-animation"}
              screenPercentHeightToStartAnimation={90}
              className={"opacity-0"}
              animateEveryTime={false}
            >
              {" "}
              <div className="opacity-60 laptop:text-[22px] tablet:text-[19px] text-[16px] text-[#111748bd]  leading-[25.06px] laptop:max-w-[30.818em] max-w-[60rem]">
                With a global footprint, PlanetBeyond is committed to serving
                clients across diverse industries and geographies. Our
                commitment to excellence and client satisfaction has earned us a
                reputation as a preferred partner for businesses seeking
                innovative software solutions.
              </div>
            </AnimateOnDisplay>
          </div>
        </div>
        <SoftwareSolutionsCardsSection />
      </div>
    </section>
  );
};

export default SoftwareSolutions;
