import React from "react";
import "../../styles/imageWaveAnimation.css";
import waveImage from "../../assets/images/wave.png";
import AnimateOnDisplay from "../../reusable/AnimateOnDisplay";

const ImageWithAnimation = ({ src, className, animatingDivHeight }) => {
  return (
    <AnimateOnDisplay
      animationClass={"animate-image"}
      className={`${className}`}
      animateEveryTime={false}
      screenPercentHeightToStartAnimation={70}
    >
      <div className="image-parent">
        <div className="w-full h-full">
          <img
            src={src}
            alt=""
            style={{ width: "100%", height: "100%" }}
            className="h-full"
          />
        </div>
        <div className="image-child">
          <div className="w-full">
            <img
              src={waveImage}
              style={{ width: "100%", height: "100%" }}
              alt=""
            />
          </div>
          <div
            className="image-child-of-child"
            style={{ "--custom-height-image-child": animatingDivHeight }}
          ></div>
        </div>
      </div>
    </AnimateOnDisplay>
  );
};

export default ImageWithAnimation;
ImageWithAnimation.defaultProps = {
  className: "",
  animatingDivHeight: "500px",
};
