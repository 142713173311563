import React from "react";
import CustomizedTooltips from "./CustomizedTooltips";

const SoftwareSolutionCard = ({
  parentClass,
  title,
  image,
  description,
  tooltip,
  bgColor,
  descriptionColorClass,
  titleColor,
  titleHeightClass,
  onTooltipOpen,
  onTooltipClose,
}) => {
  return (
    <div
      className={`select-none px-4 py-6 tablet:px-6 laptop:px-10 desktop:px-12 tablet:py-8 laptop:py-12  
      flex flex-col justify-around
            w-full max-w-[300px] min-w-[300px] laptop:max-w-[414px] laptop:min-w-[414px] tablet:h-[512px]  mobile:h-[497px] h-[490px] aspect-w-9 aspect-h-11 ${parentClass}`}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div
        style={{ color: titleColor }}
        className={`pointer-events-none uppercase laptop:text-[39px] tablet:text-[34px] mobile:text-[32px] text-[30px] font-[900] ${titleHeightClass} leading-10`}
      >
        {title}
      </div>
      <div className="pointer-events-none aspect-w-1 flex justify-center aspect-h-1 ">
        <img className="w-[45%] object-cover" src={image} alt="Img Here" />
      </div>
      <span
        className={`pointer-events-none font-[400]  laptop:text-[20px] mobile:text-[19px]  text-[16px] leading-6 mt-1 ${descriptionColorClass}`}
      >
        {description}...
        <CustomizedTooltips
          btnClassName={`pointer-events-auto text-[20px] font-[700]  ${descriptionColorClass}`}
          note={tooltip}
          onOpen={onTooltipOpen}
          onClose={onTooltipClose}
        />
      </span>
    </div>
  );
};

export default SoftwareSolutionCard;

SoftwareSolutionCard.defaultProps = {
  parentClass: "",
  bgColor: "#111748",
  titleColor: "#fff",
  descriptionColorClass: "text-[#fff]",
  title: "title Here",
  description:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem placeat repudiandae repellat aliquam ducimus eaque consectetur excepturi eius, vero unde!",
  tootTip:
    "Our team of experienced developers excels in creating bespoke software solutions tailored to me From conceptualization to deployment, we ensure a seamless and efficient development process.",
  titleHeightClass: "min-h-[135px]",
  onTooltipOpen: () => {},
  onTooltipClose: () => {},
};
