export default function elementIsBeingDisplayed(
  elementId,
  screenPercentHeightToStartAnimation
) {
  const element = document.getElementById(elementId);
  if (element) {
    const elementTop = element.getBoundingClientRect().top;
    const viewportHeight = window.innerHeight;
    const elementIsBeingDisplayed =
      elementTop < viewportHeight * (screenPercentHeightToStartAnimation / 100);
    //  elementTop > 0 && elementTop < viewportHeight;
    return elementIsBeingDisplayed;
  }
  return false;
}
